import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  /*{
    path: '/auth/register',
    name: 'register',
    component: () => import('./views/auth/Register'),
    meta: { layout: 'empty-layout', guest: true },
  },*/
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('./views/auth/Login'),
    meta: { layout: 'empty-layout', guest: true },
  },
  {
    path: '/auth/verify-email',
    name: 'verifyEmail',
    component: () => import('./views/auth/VerifyEmail'),
    meta: { layout: 'empty-layout', guest: true },
    props: (route) => ({ token: route.query.token }),
  },
  /*{
    path: '/auth/request-password-reset',
    name: 'requestPasswordReset',
    component: () => import('./views/auth/RequestPasswordReset'),
    meta: { layout: 'empty-layout', guest: true },
  },
  {
    path: '/auth/password-reset',
    name: 'passwordReset',
    component: () => import('./views/auth/PasswordReset'),
    meta: { layout: 'empty-layout', guest: true },
    props: (route) => ({ token: route.query.token }),
  },*/

  {
    path: '/calculator',
    name: 'calculator',
    component: () => import('./views/calculator/Calculator'),
    meta: { layout: 'calculator-layout', auth: false },
  },

  {
    path: '/',
    name: 'dashboard',
    //component: () => import('./views/Dashboard'),
    //meta: { layout: 'main-layout', auth: true },
    redirect: '/orders',
  },

  // Пользователи
  {
    path: '/users',
    name: 'users',
    component: () => import('./views/users/Users'),
    meta: { layout: 'main-layout', auth: true, can: ['apiUsersL'] },
  },
  {
    path: '/users/add',
    name: 'addUser',
    component: () => import('./views/users/AddUser'),
    meta: { layout: 'main-layout', auth: true, can: ['apiUsersC'] },
  },
  {
    path: '/users/:username',
    name: 'userProfile',
    component: () => import('./views/users/UserProfile'),
    meta: { layout: 'main-layout', auth: true, can: ['apiUsersU','apiUsersUO'] },
    props: (route) => ({ username: route.params.username }),
  },

  // Настройки
  {
    path: '/settings/calculator',
    name: 'calculatorSettings',
    component: () => import('./views/settings/CalculatorSettings'),
    meta: { layout: 'main-layout', auth: true, can: ['apiSettingsL'] },
  },
  {
    path: '/settings/telegram',
    name: 'telegramSettings',
    component: () => import('./views/settings/TelegramSettings'),
    meta: { layout: 'main-layout', auth: true, can: ['apiSettingsL'] },
  },
  {
    path: '/settings/sms',
    name: 'smsSettings',
    component: () => import('./views/settings/SmsSettings'),
    meta: { layout: 'main-layout', auth: true, can: ['apiSettingsL'] },
  },
  {
    path: '/settings/locale',
    name: 'localeSettings',
    component: () => import('./views/settings/LocaleSettings'),
    meta: { layout: 'main-layout', auth: true, can: ['apiSettingsL'] },
  },
  // Языки
  {
    path: '/settings/languages',
    name: 'languages',
    component: () => import('./views/settings/languages/Languages'),
    meta: { layout: 'main-layout', auth: true, can: ['apiLanguagesL'] },
  },
  {
    path: '/settings/languages/add',
    name: 'addLanguage',
    component: () => import('./views/settings/languages/AddLanguage'),
    meta: { layout: 'main-layout', auth: true, can: ['apiLanguagesC'] },
  },
  {
    path: '/settings/languages/:id/edit',
    name: 'editLanguage',
    component: () => import('./views/settings/languages/EditLanguage'),
    meta: { layout: 'main-layout', auth: true, can: ['apiLanguagesU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  // Валюты
  {
    path: '/settings/currencies',
    name: 'currencies',
    component: () => import('./views/settings/currencies/Currencies'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCurrenciesL'] },
  },
  {
    path: '/settings/currencies/add',
    name: 'addCurrency',
    component: () => import('./views/settings/currencies/AddCurrency'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCurrenciesC'] },
  },
  {
    path: '/settings/currencies/:id/edit',
    name: 'editCurrency',
    component: () => import('./views/settings/currencies/EditCurrency'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCurrenciesU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Клиенты
  {
    path: '/clients',
    name: 'clients',
    component: () => import('./views/clients/Clients'),
    meta: { layout: 'main-layout', auth: true, can: ['apiClientsL'] },
  },
  {
    path: '/clients/add',
    name: 'addClient',
    component: () => import('./views/clients/AddClient'),
    meta: { layout: 'main-layout', auth: true, can: ['apiClientsC'] },
  },
  {
    path: '/clients/:id/edit',
    name: 'editClient',
    component: () => import('./views/clients/EditClient'),
    meta: { layout: 'main-layout', auth: true, can: ['apiClientsU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Брэнды профиля
  {
    path: '/opening-type-brands',
    name: 'openingTypeBrands',
    component: () => import('./views/opening-type-brands/OpeningTypeBrands'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOpeningTypeBrandsL'] },
  },
  {
    path: '/opening-type-brands/add',
    name: 'addOpeningTypeBrand',
    component: () => import('./views/opening-type-brands/AddOpeningTypeBrand'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOpeningTypeBrandsC'] },
  },
  {
    path: '/opening-type-brands/:id/edit',
    name: 'editOpeningTypeBrand',
    component: () => import('./views/opening-type-brands/EditOpeningTypeBrand'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOpeningTypeBrandsU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Профили
  {
    path: '/profiles',
    name: 'profiles',
    component: () => import('./views/profiles/Profiles'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProfilesL'] },
  },
  {
    path: '/profiles/add',
    name: 'addProfile',
    component: () => import('./views/profiles/AddProfile'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProfilesC'] },
  },
  {
    path: '/profiles/:id/edit',
    name: 'editProfile',
    component: () => import('./views/profiles/EditProfile'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProfilesU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Цвета профиля
  {
    path: '/profile-colors',
    name: 'profileColors',
    component: () => import('./views/profile-colors/ProfileColors'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProfileColorsL'] },
  },
  {
    path: '/profile-colors/add',
    name: 'addProfileColor',
    component: () => import('./views/profile-colors/AddProfileColor'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProfileColorsC'] },
  },
  {
    path: '/profile-colors/:id/edit',
    name: 'editProfileColor',
    component: () => import('./views/profile-colors/EditProfileColor'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProfileColorsU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Цвета стекла
  {
    path: '/glass-colors',
    name: 'glassColors',
    component: () => import('./views/glass-colors/GlassColors'),
    meta: { layout: 'main-layout', auth: true, can: ['apiGlassColorsL'] },
  },
  {
    path: '/glass-colors/add',
    name: 'addGlassColor',
    component: () => import('./views/glass-colors/AddGlassColor'),
    meta: { layout: 'main-layout', auth: true, can: ['apiGlassColorsC'] },
  },
  {
    path: '/glass-colors/:id/edit',
    name: 'editGlassColor',
    component: () => import('./views/glass-colors/EditGlassColor'),
    meta: { layout: 'main-layout', auth: true, can: ['apiGlassColorsU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Дополнительные услуги
  {
    path: '/add-services-glass',
    name: 'addServicesGlass',
    component: () => import('./views/add-services-glass/AddServicesGlass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAddServicesGlassL'] },
  },
  {
    path: '/add-services-glass/add',
    name: 'addAddServiceGlass',
    component: () => import('./views/add-services-glass/AddAddServiceGlass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAddServicesGlassC'] },
  },
  {
    path: '/add-services-glass/:id/edit',
    name: 'editAddServiceGlass',
    component: () => import('./views/add-services-glass/EditAddServiceGlass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAddServicesGlassU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Тип открывания
  {
    path: '/opening-types',
    name: 'openingTypes',
    component: () => import('./views/opening-types/OpeningTypes'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOpeningTypesL'] },
  },
  {
    path: '/opening-types/add',
    name: 'addOpeningType',
    component: () => import('./views/opening-types/AddOpeningType'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOpeningTypesC'] },
  },
  {
    path: '/opening-types/:id/edit',
    name: 'editOpeningType',
    component: () => import('./views/opening-types/EditOpeningType'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOpeningTypesU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Услуги сборки
  {
    path: '/assembly-services',
    name: 'assemblyServices',
    component: () => import('./views/assembly-services/AssemblyServices'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAssemblyServicesL'] },
  },
  {
    path: '/assembly-services/add',
    name: 'addAssemblyService',
    component: () => import('./views/assembly-services/AddAssemblyService'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAssemblyServicesC'] },
  },
  {
    path: '/assembly-services/:id/edit',
    name: 'editAssemblyService',
    component: () => import('./views/assembly-services/EditAssemblyService'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAssemblyServicesU'] },
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Заказы
  {
    path: '/orders',
    name: 'orders',
    component: () => import('./views/orders/Orders'),
    meta: { layout: 'main-layout', auth: true, can: ['apiOrdersL', 'apiOrdersLO'] },
  },

  // 404
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () => import('./views/NotFound'),
    meta: { layout: 'main-layout', auth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: '',
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');

  let user = null;
  try {
    user = JSON.parse(loggedIn);
  } catch (err) {
    user = null;
  }

  if (to.matched.some(record => record.meta.auth)) { // Если маршрут только для авторизованых
    if (!loggedIn) { // Если не авторизован
      next({ name: 'login' });
    } else if (to.matched.some(record => record.meta.can)) { // Если маршрут требует разрешения
      let can = false;
      for (let i = 0; i < to.meta.can.length; i++) {
        if (user.permissions.indexOf(to.meta.can[i]) > -1) {
          can = true;
          break;
        }
      }

      if (can) {
        next();
      } else {
        next({ name: 'notfound' });
      }
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) { // Если маршрут только для гостей
    if (loggedIn) { // Если авторизован
      next({ name: 'dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
