export const dictRole = {
  //superadmin: 'Главный админ',
  superadmin: 'Администратор',
  //admin: 'Администратор',
  collector: 'Сборщик',
  customer: 'Заказчик',
}

export const dictOrderStatus = {
  0: 'Удален',
  1: 'Расчет',
  2: 'В ожидании',
  3: 'В работе',
  10: 'Выполнен',
  11: 'Отгружен',
  12: 'Отменен',
}

export const dictProfileCalculationType = {
  1: 'Стандартные профили',
  2: 'Остальные профили',
}

export const dictOpeningTypesCalculationType = {
  1: 'Накладная ручка',
  2: 'Встроенная ручка',
}
